import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import PaymentGuard from '../guards/PaymentGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from 'guards/RoleBasedGuard';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'confirm-password', element: <ConfirmPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Admin Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <RoleBasedGuard>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: 'generated-lessons', element: <GeneratedLessons /> },
        { path: 'generator', element: <BulkLessonGenerator /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/home/" replace /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        { path: 'home', element: <Dashboard /> },
        { path: 'planner', element: <Kanban /> },
        { path: 'tutorials', element: <VideoTutorials /> },
      ],
    },

    // Main Routes  
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'success-subscription',
          element: (
            <PaymentGuard>
              <SuccessSubscription />
            </PaymentGuard>
          ),
        },
        {
          path: 'expired-subscription',
          element: (
            <PaymentGuard>
              <ExpiredSubscription />
            </PaymentGuard>
          ),
        },
        { path: 'terms-of-service', element: <TermsOfService /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ConfirmPassword = Loadable(lazy(() => import('../pages/authentication/ConfirmPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Admin
const GeneratedLessons = Loadable(lazy(() => import('../pages/admin/GeneratedLessons')));
const BulkLessonGenerator = Loadable(lazy(() => import('../pages/admin/BulkLessonGenerator')));

// Dashboard
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const ExpiredSubscription = Loadable(lazy(() => import('../pages/dashboard/ExpiredSubscription')));
const SuccessSubscription = Loadable(lazy(() => import('../pages/dashboard/SuccessSubscription')));
const VideoTutorials = Loadable(lazy(() => import('../pages/dashboard/VideoTutorials')));
// Main
const TermsOfService = Loadable(lazy(() => import('../pages/TermsOfService')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
