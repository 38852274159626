import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_DASHBOARD } from 'routes/paths';
import { actionTag } from 'utils/analytics';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const authenticated = localStorage.getItem('accessToken');
  useEffect(() => {
    if (!!authenticated) actionTag({ category: 'Account', action: 'Login' });
  }, []);


  if (!!authenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
