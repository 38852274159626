import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { Class } from 'types/class';
import { AppStatus } from 'types/general';
import { createClass, getClasses, deleteClass, getClassMetrics, copyClass } from './classThunk';
import { defineState } from 'redux/persist';

type ClassState = {
  status: AppStatus;
  error?: null | string;
  classes: Class[];
  metrics: Record<string, any>
};

const defaultState: ClassState = {
  status: 'idle',
  error: '',
  classes: [],
  metrics: {
    skills: 0,
    skillsCovered: 0,
    skillsPlanned: 0,
    skillsNotPlanned: 0,
  },
};

const initialState = defineState(defaultState)('class');

export const ClassSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    clearStatus(state) {
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClasses.fulfilled, (state, { payload }) => {
        state.classes =  payload;
        state.status = 'resolved';
      })
      .addCase(deleteClass.fulfilled, (state, { payload }) => {
        state.status = 'resolved';
      })
      .addCase(getClassMetrics.fulfilled, (state, { payload }) => {
        state.status = 'resolved';
        state.metrics = payload;
      })
      .addMatcher(
        isFulfilled(createClass, copyClass), 
        (state, { payload }) => {
          state.status = 'resolved';
          state.classes.push(payload!);
        },
      )
      .addMatcher(
        isPending(createClass, getClasses, deleteClass, getClassMetrics, copyClass),
        (state) => {
          state.status = 'pending';
        },
      )
      .addMatcher(
        isRejected(createClass, getClasses, deleteClass, getClassMetrics, copyClass),
        (state, { error, type }) => {
          state.error = error.message;
          state.status = 'rejected';
        },
      );
  },
});

export const selectClassState = (state: RootState) => state.class;

export const {
  clearStatus,
} = ClassSlice.actions;

export default ClassSlice.reducer;