// routes
import { PATH_ADMIN, PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  calendar: getIcon('ic_calendar'),
  dashboard: getIcon('ic_dashboard'),
  generator: getIcon('ic_generator'),
};

const sidebarConfig = [
  // APP
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.dashboard, icon: ICONS.calendar },
      {
        title: 'Planner',
        path: PATH_DASHBOARD.planner,
        icon: ICONS.calendar,
      },
      {
        title: 'Generator',
        path: PATH_ADMIN.generatedLessons,
        icon: ICONS.generator,
      },
    ],
  },
];

export default sidebarConfig;
