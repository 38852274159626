import { combineReducers } from 'redux';
// slices
import userSlice from './user/userSlice';
import lessonSlice from './lesson/lessonSlice';
import calendarReducer from './slices/calendar';
import classSlice from './class/classSlice';
import billingSlice from './billing/billingSlice';
import bnccSlice from './bncc/bnccSlice';

// ----------------------------------------------------------------------


const rootReducer = combineReducers({
  user: userSlice,
  calendar: calendarReducer,
  lesson: lessonSlice,
  class: classSlice,
  billing: billingSlice,
  bncc: bnccSlice,
});

export { rootReducer };
