import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import { useGetUser } from 'hooks/use-user';
// pages
import { PATH_AUTH } from 'routes/paths';


// ----------------------------------------------------------------------

type PaymentGuardProps = {
  children: ReactNode;
};

export default function PaymentGuard({ children }: PaymentGuardProps) {
  const { user } = useGetUser();

  const authentication = localStorage.getItem('accessToken');
  if (!user && !authentication) {
    return <Navigate to={PATH_AUTH.login} />;
  }

  return <>{children}</>;
}
