import { ReactNode } from 'react';
import { useGetUser } from 'hooks/use-user';
import { Navigate } from 'react-router';

// ----------------------------------------------------------------------

const admins = [
  'fwdesouza@gmail.com',
  'dmhbr24@gmail.com',
];

type RoleBasedGuardProp = {
  children: ReactNode | string;
};

const useCurrentRole = () => {
  // Logic here to get current user role
  const role = 'admin';
  return role;
};

export default function RoleBasedGuard({ children }: RoleBasedGuardProp) {
  const { user } = useGetUser();
  const currentRole = useCurrentRole();
  if (!admins.includes(user?.email)){
    return <Navigate to={'/404'} />;
  }

  if (user && user?.permission && !user?.permission.includes(currentRole)) {
    return <Navigate to={'/404'} />;
  }

  return <>{children}</>;
}
