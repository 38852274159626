import { getTags, getTagsWithFilter } from 'modules/bncc';
import { copyClass, createClass, deleteClass, editClass, getClass, getClassMetrics } from 'modules/class';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';


export const useGetClass = (): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['class'],
    queryFn: getClass,
    refetchOnWindowFocus: false,
  });
};

export const useCreateClass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createClass,
    onSuccess: () => {
      queryClient.invalidateQueries('class');
    },
  });
};

export const useEditClass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editClass,
    onSuccess: () => {
      queryClient.invalidateQueries('class');
    },
  });
};

export const useCopyClass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: copyClass,
    onSuccess: () => {
      queryClient.invalidateQueries('class');
    },
  });
};

export const useGetMetricsClass = (classId: string): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['metrics', classId],
    queryFn: () => getClassMetrics(classId),
    refetchOnWindowFocus: false,
    enabled: !!classId,
  });
};

export const useDeleteClass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteClass,
    onSuccess: () => {
      queryClient.invalidateQueries('class');
    },
  });
};

export const useGetTags = (classId: string): UseQueryResult<any> => {
  return useQuery({
    queryKey: ['tags', classId],
    queryFn: () => getTags(classId),
    refetchOnWindowFocus: false,
    enabled: !!classId,
  });
};

export const useGetTagsWithFilters = () => {
  return useMutation({
    mutationFn: getTagsWithFilter,
  });
};