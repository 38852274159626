import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AppStatus } from 'types/general';

import { paySubscriptionThunk, billingSessionThunk } from './billingThunk';

type BillingType = {
  sessionUrl?: string;
  status: AppStatus;
  error?: null | string;
};

const initialState: BillingType = {
  sessionUrl: undefined,
  error: null,
  status: 'idle',
};

export const billingSlice = createSlice({
  name: 'billingSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(paySubscriptionThunk.fulfilled, (state, { payload }) => {
        state.sessionUrl = payload.stripeSubscription;
        state.status = 'resolved';
      })
      .addCase(billingSessionThunk.fulfilled, (state, { payload }) => {
        state.sessionUrl = payload.sessionUrl;
        state.status = 'resolved';
      })
      .addMatcher(
        isPending(paySubscriptionThunk, billingSessionThunk),
        (state) => {
          state.status = 'pending';
        },
      )
      .addMatcher(
        isRejected(paySubscriptionThunk, billingSessionThunk),
        (state, { error }) => {
          state.error = error.message;
          state.status = 'rejected';
        },
      );
  },
});

export const selectBillingState = (state: RootState) => state.billing;

// Reducer
export default billingSlice.reducer;
