import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginRequest, Profile, RegisterRequest, UpdatePasswordRequest } from 'types/user';
import * as HTTP from 'utils/http';
import { Auth } from 'aws-amplify';
import { SchoolConfig } from 'types/school';

export const login = createAsyncThunk(
  'login',
  async ({ email, password }: LoginRequest) => {
    try {
      await Auth.signIn(email, password);
      const { payload } = await HTTP.get('/profile');
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const logout = createAsyncThunk(
  'logout',
  async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const register = createAsyncThunk(
  'register',
  async (userRegister: RegisterRequest & { token: string }) => {
    try {
      const { payload } = await HTTP.post('/users', userRegister);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const updatePassword = createAsyncThunk(
  'updatePassword',
  async (updatePassword: UpdatePasswordRequest) => {
    try {
      const { payload } = await HTTP.patch('/password', updatePassword);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const updateSchoolSubjects = createAsyncThunk(
  'updateSchoolSubjects',
  async (updateSchoolSubjects: SchoolConfig) => {
    try {
      const { payload } = await HTTP.patch('/profile', updateSchoolSubjects);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const updateProfileThunk = createAsyncThunk(
  'updateProfile',
  async (request: Profile & { newProfilePicture: any }) => {
    try {
      const { userId, newProfilePicture } = request;
      if (newProfilePicture) {
        await HTTP.patch('/profile/image', { image: newProfilePicture });
        request.photoURL = `${process.env.REACT_APP_CLOUDFRONT}/profiles/${userId}.jpg`;
      }
      const { payload } = await HTTP.patch('/profile', request);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const getUserProfileThunk = createAsyncThunk(
  'getUserProfile',
  async () => {
    try {
      const { payload } = await HTTP.get('/profile');
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const resetPasswordThunk = createAsyncThunk(
  'resetPassword',
  async (request: { email: string }) => {
    try {
      await HTTP.post('/user/reset-password', request);
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const confirmPasswordThunk = createAsyncThunk(
  'confirmPassword',
  async (request: { id: string, password: string }) => {
    try {
      await HTTP.post('/user/confirm-password', request);
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);
