import { getUser, updatePassword, updateUser } from 'modules/user';
import { useMutation, useQuery, useQueryClient, UseQueryResult  } from 'react-query';
import { Profile } from 'types/user';

type UserData = {
  success: boolean;
  payload: Record<string, any>;
};

export const useGetUser = () => {
  const data:UseQueryResult<UserData> = useQuery({
    queryKey: ['profile'],
    queryFn: getUser,
  });

  const { error, status, data: userData } = data;
  const { school, subjects } = userData?.payload as Profile || {};
  const { profile: schoolProfile, stages: schoolStages } = school || {};
  return {
    user: userData?.payload as Profile,
    schoolProfile,
    schoolStages,
    subjects,
    status,
    error,
    data,
  };
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries('profile');
    },
  });
};

export const useUpdatePassword = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePassword,
    onSuccess: () => {
      queryClient.invalidateQueries('profile');
    },
  });
};