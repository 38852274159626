import { RegisterRequest } from 'types/user';
import axios, { API_ENDPOINTS } from 'utils/https'; 

export const login = async (data: { email:string, password: string }) => {
  const response = await axios.post(API_ENDPOINTS.auth.login, data); 
  return response;
};

export const register = async (data: RegisterRequest & { token: string }) => {
  const response = await axios.post(API_ENDPOINTS.auth.register, data);
  return response;
};

export const resetPassword = async (data: { email: string }) => {
  const response = await axios.post(API_ENDPOINTS.auth.resetPassword, data);
  return response;
};

export const confirmPassword = async (data: { password: string, id: string }) => {
  const response = await axios.post(API_ENDPOINTS.auth.confirmPassword, data);
  return response;
};