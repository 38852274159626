//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import defaultPicture from 'assets/default.jpg';
import { useGetUser } from 'hooks/use-user';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useGetUser();

  return (
    <MAvatar
      src={user?.photoURL || defaultPicture}
      alt={user?.firstName}
      {...other}
    />
  );
}
