import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'routes/paths';
// hooks
import { useGetUser } from 'hooks/use-user';
import { useTranslation } from 'react-i18next';
// components
import { MIconButton } from 'components/@material-extend';
import MyAvatar from 'components/MyAvatar';
import MenuPopover from 'components/MenuPopover';
import { actionTag } from '../../utils/analytics';
import { useLogout } from 'hooks/use-auth';
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { user } = useGetUser();

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const logout = useLogout();

  const MENU_OPTIONS = [
    {
      label: t('Home'),
      icon: homeFill,
      linkTo: '/',
    },
    {
      label: t('Settings'),
      icon: settings2Fill,
      linkTo: PATH_DASHBOARD.user.account,
    },
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (selectedMenu: string) => {
    actionTag({ category: 'Account Menu', action: 'Opened', label: selectedMenu });
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={() => {
              handleClose(option.label);
            }}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
            {t('Logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
