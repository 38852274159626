import { Profile, UpdatePasswordRequest } from 'types/user';
import axios, { API_ENDPOINTS } from 'utils/https';

export const getUser = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await axios.get(API_ENDPOINTS.user.getUser, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  const user = response;
  return user;
};
export const updateUser = async (data: Profile & { newProfilePicture?: string }) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await axios.patch(API_ENDPOINTS.user.getUser, data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};
export const updatePassword = async (data: UpdatePasswordRequest) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await axios.patch(API_ENDPOINTS.user.updatePassword, data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};