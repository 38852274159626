// material
import { Box, BoxProps } from '@mui/material';

// logo
import PlanBookLogo from '../assets/planbook_logo.png';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 230, height: 50, ...sx }}>
      <img src={PlanBookLogo} alt="Planbook logo" />
    </Box>
  );
}
