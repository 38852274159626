import { createAsyncThunk } from '@reduxjs/toolkit';
import { KanbanCard } from 'types/kanban';
import * as HTTP from 'utils/http';
import { v4 as uuidv4 } from 'uuid';

type LessonCard = KanbanCard & { classId: string };

export const getClassLessons = createAsyncThunk(
  'getClassLessons',
  async (request: { classId: string }) => {
    try {
      const { classId } = request;
      const promises = [HTTP.get(`/classes/${classId}/lessons`), HTTP.get(`/classes/${classId}/tags`)];
      const [ lessonsData, tagsData ] = await Promise.all(promises);
      return { lesson: lessonsData.payload, tags: tagsData.payload };
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const createLessonThunk = createAsyncThunk(
  'createLessonThunk',
  async (request: LessonCard) => {
    try {
      const { classId, ...lesson } = request;
      const values = {
        lessonId: uuidv4(),
        ...lesson,
      };

      const { payload } = await HTTP.post(`/classes/${classId}/lessons`, values);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const updateLessonThunk = createAsyncThunk(
  'updateLessonThunk',
  async (request: LessonCard & { lessonId: string }) => {
    try {
      const { classId, lessonId, ...values } = request;
      const { payload } = await HTTP.patch(`/classes/${classId}/lessons/${lessonId}`, values);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const deleteLessonThunk = createAsyncThunk(
  'deleteLessonThunk',
  async (request: { classId: string, lessonId: string }) => {
    try {
      const { classId, lessonId } = request;
      const { payload } = await HTTP.del(`/classes/${classId}/lessons/${lessonId}`);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const getLessonByBNCCTagThunk = createAsyncThunk(
  'getLessonByBNCCTag',
  async (request: { classId: string, cod: string }) => {
    try {
      const { classId, cod } = request;
      const { payload } = await HTTP.get(`/classes/${classId}/bncc/${cod}`);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);