import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { rootReducer } from './rootReducer';
import { persistStore } from './persist';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer:  rootReducer,
  devTools: true,
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch<AppDispatch>();

persistStore(store);

export { store, dispatch, useSelector, useDispatch };
