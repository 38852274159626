import { API } from 'aws-amplify';

export const get = async (path: string, request = {}) => {
  try {
    return await API.get('planbook', path, request);
  } catch (error) {
    throw new Error(error.response?.data.message || error.message) ;
  }
};

export const post = async (path: string, request: object) => {
  try {
    const params = {
      body: request,
    };
    return await API.post('planbook', path, params);
  } catch (error) {
    throw new Error(error.response?.data.message || error.message) ;
  }
};

export const patch = async (path: string, request: object) => {
  try {
    const params = {
      body: request,
    };
    return await API.patch('planbook', path, params);
  } catch (error) {
    throw new Error(error.response?.data.message || error.message);
  }
};

export const del = async (path: string, request?: object) => {
  try {
    const params = {
      body: request,
    };
    return await API.del('planbook', path, params);
  } catch (error) {
    throw new Error(error.response?.data.message || error.message);
  }
};
