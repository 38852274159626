import { useMutation } from 'react-query';

import { confirmPassword, login, register, resetPassword } from 'modules/auth';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD, PATH_AUTH } from 'routes/paths';


export const useLogin = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: login,
    onSuccess: (data: Record<string, any>) => {
      const accessToken = data.accessToken;
      localStorage.setItem('accessToken', accessToken);
      return navigate(PATH_DASHBOARD.root);
    },
  });
};

export const useRegister = () => {
  return useMutation({
    mutationFn: register,
    onSuccess: (data: Record<string, any>) => {
      localStorage.setItem('accessToken', data.accessToken);
    },
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};

export const useConfirmPassword = () => {
  return useMutation({
    mutationFn: confirmPassword,
  });
};

export const useLogout = () => {
  const navigate = useNavigate();

  return () => {
    localStorage.removeItem('accessToken');
    navigate(PATH_AUTH.login);
  };
};