import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Link,
  Typography,
} from '@mui/material';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { useGetUser } from 'hooks/use-user';
import { useTranslation } from 'react-i18next';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import { actionTag } from '../../utils/analytics';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;
const URL_WHATSAPP_INVITE = 'https://api.whatsapp.com/send?text=Ol%C3%A1!%20Estou%20usando%20o%20Planbook%20(planbook.com.br)%20para%20planejar%20minhas%20aulas%20com%20diretrizes%20da%20BNCC';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { user } = useGetUser();
  const { t } = useTranslation();

  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center',
          }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>
        </Stack>

        {isCollapse ? (
          <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
        ) : (
            <AccountStyle>
              <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.account}>
                <MyAvatar />
              </Link>
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user?.firstName}
                </Typography>
                <Link underline="none" variant="body2" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', gap: '5px' }} href={URL_WHATSAPP_INVITE} target="_blank" rel="noreferrer" >
                    <span onClick={() => actionTag({ category: 'Share', action: 'Clicked', label: 'WhatsApp Share Button' })}>
                      {t('Invite teacher on')}  
                    </span>
                    <WhatsappIcon sx={{ fontSize: 18 }} color="success"/>
                </Link>
              </Box>
            </AccountStyle>
        )}
            <AccountStyle>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'justify', gap: '10px', ml: 2 }}>
              <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.tutorials}>
              <YouTubeIcon sx={{ color: 'red', fontSize: 50, mx: 'auto' }}/>
              </Link>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                </Typography>
                <Link 
                  component={RouterLink}
                  to={PATH_DASHBOARD.tutorials}
                  underline="none" 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary', 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '5px',
                  }} 
                >
                    <span onClick={() => actionTag({ category: 'Share', action: 'Clicked' })}>
                    {t('Learn how to make magic with Planbook')} 
                    </span>
                </Link>
                </Box>
            </AccountStyle>
      </Stack>
      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} user={user}/>
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
