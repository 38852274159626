import { createAsyncThunk } from '@reduxjs/toolkit';
import * as HTTP from 'utils/http';

export const paySubscriptionThunk = createAsyncThunk(
  'paySubscription',
  async (request: { priceId: string }) => {
    try {
      const { payload } = await HTTP.post('/subscription/stripe/session', request);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const billingSessionThunk = createAsyncThunk(
  'billingSession',
  async () => {
    try {
      const { payload } = await HTTP.post('/billing/stripe/session', {});
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);
