import axios from 'axios';
import { HOST_API } from './config';
import { Filters } from 'types/bncc';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response?.data?.message || 
error.response?.data?.error ||
'Something went wrong'),
);

export default axiosInstance;

export const API_ENDPOINTS = {
  auth: {
    login: '/auth/login',
    register: '/auth/users',
    resetPassword: '/auth/reset-password',
    confirmPassword: '/auth/confirm-password',
  },
  user: {
    getUser: '/user/profile',
    updatePassword: '/user/password',
  },
  class: {
    getClass: '/classes',
    createClass: '/classes',
    copyClass: '/classes/copy',
    editClass:(classId: string) => `/classes/${classId}`,
    getMetricsClass: (classId: string) => `/classes/metrics/${classId}`,
    deleteClass: (classId: string) => `/classes/${classId}`,
  },
  school: {
    getCities: '/cities',
  },
  lesson: {
    createLesson: (classId: string) => `/classes/${classId}/lessons`,
    deleteLesson: (classId: string, lessonId: string) => `/classes/${classId}/lessons/${lessonId}`,
    updateLesson: (classId: string, lessonId: string) => `/classes/${classId}/lessons/${lessonId}`,
    getLesson: (classId: string) => `/classes/${classId}/lessons`,
    getLessonByBNCCTag: (classId: string, cod: string) => `/classes/${classId}/bncc/${cod}`,
    generateLessonByAI: '/classes/lessons/ai/bulk',
    generateLessonWithAI: (classId: string, lessonId: string) => `/classes/${classId}/lessons/${lessonId}/ai-enhance`,
  },
  generatedLesson: {
    getGeneratedLesson: '/generated-lessons',
    insertGeneratedLesson: '/generated-lessons/insert',
    updateStatus: (generatedLessonId: string) => `/generated-lessons/${generatedLessonId}/status`,
    updateLessonJSON: (generatedLessonId: string) => `/generated-lessons/${generatedLessonId}/lesson`,
  },
  bncc:{
    getTags: (classId: string) => `/classes/${classId}/tags`,
    getTagsWithFilters: (payload: Filters) => {
      let url = '/tags?';
      if (payload.stage) {
        url += `stage=${payload.stage}`;
      }
      if (payload.stage !== 'infantil' && payload.topicUnits.length) {
        url += `&subjects=${payload.topicUnits.join(',')}`;
      }
      if (payload.years.length) {
        url += `&years=${payload.years.join(',')}`;
      }
      return url;
    },
  },
  billing: {
    paySubscription: '/subscription/stripe/session',
    billingSession: '/billing/stripe/session',
    webhook: '/billing/stripe/webhook',
  },
};