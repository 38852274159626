interface AnalyticsTag {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

export const actionTag = ({ category, action, label, value }: AnalyticsTag) => {

  const tagContent: Record<string, any> = {
    'event_category': category,
  };
  if (label) { tagContent.event_label = label; }
  if (value) { tagContent.value = value; }

  // @ts-ignore
  window.gtag('event', action, tagContent);

};

