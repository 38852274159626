import { Class } from 'types/class';
import axios, { API_ENDPOINTS } from 'utils/https';

export const getClass = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await axios.get(API_ENDPOINTS.class.getClass, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};

export const createClass = async (data: Class) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await axios.post(API_ENDPOINTS.class.createClass, data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};

export const editClass = async (data: Class) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await axios.patch(API_ENDPOINTS.class.editClass(data.classId), data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};

export const copyClass = async (data: Class & { originId: string }) => {
  const { originId, ...values } = data;
  const accessToken = localStorage.getItem('accessToken');
  const response = await axios.post(API_ENDPOINTS.class.copyClass, { originId, ...values }, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};

export const getClassMetrics = (classId: string) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = axios.get(API_ENDPOINTS.class.getMetricsClass(classId), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};

export const deleteClass = async (classId: string ) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = axios.delete(API_ENDPOINTS.class.deleteClass(classId), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};