import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { Profile } from 'types/user';


// import { User } from 'types/account'; // Replace "Profile"

import { login, register, updatePassword, logout, updateProfileThunk, getUserProfileThunk, resetPasswordThunk, confirmPasswordThunk } from './userThunk';
import { RootState } from 'redux/store';
import { AppStatus } from 'types/general';
import { defineState } from 'redux/persist';

type UserState = {
  profile?: Profile;
  error?: null | string;
  status: AppStatus;
};

const defaultState: UserState = {
  profile: undefined,
  error: null,
  status: 'idle',
};

const initialState = defineState(defaultState)('user');

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearStatus(state) {
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        state.profile =  payload;
        state.status = 'resolved';
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        state.profile =  undefined;
        state.status = 'resolved';
        sessionStorage.removeItem('store');
      })
      .addCase(updateProfileThunk.fulfilled, (state, { payload }) => {
        state.profile = { ...state.profile, ...payload };
        state.status = 'resolved';
      })
      .addCase(getUserProfileThunk.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.status = 'resolved';
      })
      .addMatcher(
        isFulfilled(register, updatePassword, resetPasswordThunk, confirmPasswordThunk),
        (state) => {
          state.status = 'resolved';
        },
      )
      .addMatcher(
        isPending(register, login, updatePassword, updateProfileThunk, getUserProfileThunk, resetPasswordThunk, confirmPasswordThunk),
        (state) => {
          state.status = 'pending';
        },
      )
      .addMatcher(
        isRejected(register, login, updatePassword, updateProfileThunk, getUserProfileThunk, resetPasswordThunk, confirmPasswordThunk),
        (state, { error }) => {
          state.error = error.message;
          state.status = 'rejected';
        },
      );
  },
});

export const selectUserState = (state: RootState) => state.user;

export const {
  clearStatus,
} = userSlice.actions;
// Reducer
export default userSlice.reducer;
