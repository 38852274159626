import { Filters } from 'types/bncc';
import axios, { API_ENDPOINTS } from 'utils/https';

export const getTags = async (classId: string) => {
  const accessToken = localStorage.getItem('accessToken');
  const response = await axios.get(API_ENDPOINTS.bncc.getTags(classId), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};

export const getTagsWithFilter = async (payload: Filters) => {
  const accessToken = localStorage.getItem('accessToken');
  const response: any = await axios.get(API_ENDPOINTS.bncc.getTagsWithFilters(payload), {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response;
};