import { createAsyncThunk } from '@reduxjs/toolkit';
import { addCommentToBnccTag, removeCommentFromBnccTag } from 'redux/lesson/lessonSlice';
import { dispatch } from 'redux/store';
import * as HTTP from 'utils/http';

export const postCommentByBNCCTagThunk = createAsyncThunk(
  'postCommentByBNCCTag',
  async (request: { content: string, id:string, threadOf?:number }) => {
    try {
      const { id, ...values } = request;
      const { payload } = await HTTP.post(`/bncc/${id}/comment`, values);
      dispatch(addCommentToBnccTag(payload));
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const reportCommentThunk = createAsyncThunk(
  'reportComment',
  async (request: { content: string, bnccId: string, commentId: number }) => {
    try {
      const { bnccId, commentId, ...values } = request;
      const { payload } = await HTTP.post(`/bncc/${bnccId}/comment/${commentId}/report`, values);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const deleteCommentThunk = createAsyncThunk(
  'deleteComment',
  async (request: { bnccId: string, commentId: number, hasReply?: boolean }) => {
    try {
      const { bnccId, commentId } = request;
      const { payload } = await HTTP.del(`/bncc/${bnccId}/comment/${commentId}`);
      dispatch(removeCommentFromBnccTag(request));
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);


