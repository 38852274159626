import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { postCommentByBNCCTagThunk, reportCommentThunk, deleteCommentThunk } from './bnccThunk';
import { RootState } from 'redux/store';
import { AppStatus } from 'types/general';
import { defineState } from 'redux/persist';

type BnccState = {
  status: AppStatus;
  error?: null | string;
};

const defaultState: BnccState = {
  status: 'idle',
  error: '',
};

const initialState = defineState(defaultState)('bncc');

export const bnccSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilled(postCommentByBNCCTagThunk, reportCommentThunk, deleteCommentThunk),
        (state) => {
          state.status = 'resolved';
        },
      )
      .addMatcher(
        isPending(postCommentByBNCCTagThunk, reportCommentThunk, deleteCommentThunk),
        (state) => {
          state.status = 'pending';
        },
      )
      .addMatcher(
        isRejected(postCommentByBNCCTagThunk, reportCommentThunk, deleteCommentThunk),
        (state, { error }) => {
          state.error = error.message;
          state.status = 'rejected';
        },
      );
  },
});

export const selectBnccState = (state: RootState) => state.bncc;

// Reducer
export default bnccSlice.reducer;
