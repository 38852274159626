import { createAsyncThunk } from '@reduxjs/toolkit';
import * as HTTP from 'utils/http';
import { Class } from 'types/class';

export const createClass = createAsyncThunk(
  'createClass',
  async (request: Class) => {
    try {
      const { success } = await HTTP.post('/classes', request);
      if (success === true) return request;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const getClasses = createAsyncThunk('getClasses', async () => {
  try {
    const { payload } = await HTTP.get('/classes');
    return payload;
  } catch (error) {
    throw new Error((error as Error).message);
  }
});

export const deleteClass = createAsyncThunk(
  'deleteClass', 
  async (request: { classId: string }) => {
    try {
      const { payload } = await HTTP.del(`/classes/${request.classId}`);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const getClassMetrics = createAsyncThunk(
  'getClassMetrics',
  async (classId: string) => {
    try {
      const { payload } = await HTTP.get(`/classes/metrics/${classId}`);
      return payload;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);

export const copyClass = createAsyncThunk(
  'copyClass',
  async (request: Class & { originId: string }) => {
    try {
      const { originId, stage, year, subject, ...values } = request;
      const { success } = await HTTP.post('/classes/copy', { originId, ...values });
      if (success) {
        return {
          stage,
          year,
          subject,
          ...values,
        };
      }
    } catch (error) {
      throw new Error((error as Error).message);
    }
  },
);
