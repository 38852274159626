import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { KanbanCard } from 'types/kanban';
import { createLessonThunk, getClassLessons, updateLessonThunk, deleteLessonThunk, getLessonByBNCCTagThunk } from './lessonThunk';
import { RootState } from 'redux/store';
import { AppStatus } from 'types/general';
import { addDays, format, startOfWeek } from 'date-fns';
import { es } from 'date-fns/locale';
import { defineState } from 'redux/persist';

type Day = {
  name: string;
  date: string;
};

type LessonState = {
  status: AppStatus;
  error?: null | string;
  lessons: {
    cards: KanbanCard[];
    days: Day[];
  };
  tags: Record<string, any>[];
  selectedTagInfo: { date: string, name: string }[];
  selectedClass?: string;
  classStatus?: AppStatus;
  tagsStatus?: AppStatus;
};

const dateNow = new Date();
const initWeekDay = startOfWeek(dateNow, { locale: es });

const generateDaysOfWeek = (initDay: Date = initWeekDay): Day[] => {
  const array = [] as Day[];
  array.push({ name: format(initDay, 'EEEE'), date: format(initDay, 'yyyy-MM-dd') });
  for (let index = 1; index <= 4; index++) {
    array.push({ name: format(addDays(initDay, index), 'EEEE'), date: format(addDays(initDay, index), 'yyyy-MM-dd') });
  }
  return array;
};

const defaultState: LessonState = {
  status: 'idle',
  error: '',
  lessons: {
    cards: [],
    days: generateDaysOfWeek(),
  },
  tags: [],
  selectedTagInfo: [],
  selectedClass: '',
  classStatus: 'idle',
  tagsStatus: 'idle',
};

const initialState = defineState(defaultState)('kanban');

export const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    createLessonReducer(state, { payload }) {
      state.lessons.cards = [...state.lessons.cards, payload];
    },
    updateLessonReducer(state, { payload }) {
      state.lessons.cards = state.lessons.cards.map(lesson => {
        if (lesson.lessonId === payload.lessonId) {
          return { ...lesson, ...payload };
        }
        return lesson;
      });
    },
    changeWeekDays(state, { payload }) {
      const initWeek = new Date(payload.weekSelect[3]);
      const start = startOfWeek(initWeek, { locale: es });
      state.lessons.days = generateDaysOfWeek(start);
    },
    selectedClassReducer(state, { payload }) {
      state.selectedClass = payload.selectedClass;
    },
    deleteLessonReducer(state, { payload }) {
      const filterLesson = state.lessons.cards.filter(lesson => {
        return lesson.lessonId !== payload.lessonId;
      });
      state.lessons.cards = filterLesson;
    },
    addCommentToBnccTag(state, { payload }) {
      const findTag = state.tags.find(tag => tag.id === Number(payload.bnccId));
      
      if (findTag) {
        if (payload.threadOf) {
          const findComment = findTag.comments.find((comment: any) => Number(comment.id) === Number(payload.threadOf.id));
          findComment.children.push(payload);
          const commentIndex = findTag.comments.indexOf(findComment);
          if (commentIndex !== -1) {
            findTag.comments[commentIndex] = findComment;
          }
          state.tags = state.tags.map(tag => {
            if (tag.id === findTag.id) return findTag;
            return tag;
          });
        } else {
          findTag.comments.push(payload);
          state.tags = state.tags.map(tag => {
            if (tag.id === findTag.id) return findTag;
            return tag;
          });
        }
      }
    },
    removeCommentFromBnccTag(state, { payload }) {
      let findTag = state.tags.find(tag => tag.id === Number(payload.bnccId));
      if (findTag) {
        const removeTag = state.tags.filter((tag) => tag.id !== findTag!.id);

        if (payload.hasReply) {
          const findParentComment = findTag.comments
            .find((comment: any) => comment.children.find((child: any) => child.id === payload.commentId));
  
          const filterCommentReplies = findParentComment
            .children.filter((child: any) => Number(child.id) !== Number(payload.commentId));
  
          findParentComment.children = filterCommentReplies;
          findTag.comments = [...findTag.comments, findParentComment];
          state.tags = [findTag, ...removeTag];
        } else {
          const filterTagComments = findTag.comments.filter((comment: any) => Number(comment.id) !== Number(payload.commentId));
          findTag.comments = filterTagComments;
          state.tags = [findTag, ...removeTag];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClassLessons.fulfilled, (state, { payload }) => {
        state.status = 'resolved';
        state.classStatus = 'resolved';
        state.lessons.cards = payload.lesson;
        state.tags = payload.tags;
      })
      .addCase(getClassLessons.pending, (state) => {
        state.status = 'pending';
        state.classStatus = 'pending';
      })
      .addCase(getClassLessons.rejected, (state, { error }) => {
        state.error = error.message;
        state.status = 'rejected';
        state.classStatus = 'rejected';
      })
      .addCase(getLessonByBNCCTagThunk.fulfilled, (state, { payload }) => {
        state.tagsStatus = 'resolved';
        state.selectedTagInfo = payload;
      })
      .addCase(getLessonByBNCCTagThunk.pending, (state) => {
        state.tagsStatus = 'pending';
      })
      .addCase(getLessonByBNCCTagThunk.rejected, (state) => {
        state.tagsStatus = 'rejected';
      })
      .addMatcher(
        isFulfilled(createLessonThunk, updateLessonThunk, deleteLessonThunk),
        (state) => {
          state.status = 'resolved';
        },
      )
      .addMatcher(
        isPending(getClassLessons, updateLessonThunk, createLessonThunk, deleteLessonThunk),
        (state) => {
          state.status = 'pending';
        },
      )
      .addMatcher(
        isRejected(getClassLessons, updateLessonThunk, createLessonThunk, deleteLessonThunk),
        (state, { error }) => {
          state.error = error.message;
          state.status = 'rejected';
        },
      );
  },
});

export const {
  createLessonReducer,
  updateLessonReducer,
  selectedClassReducer,
  changeWeekDays,
  deleteLessonReducer,
  addCommentToBnccTag,
  removeCommentFromBnccTag,
} = lessonSlice.actions;

export const selectLessonState = (state: RootState) => state.lesson;

// Reducer
export default lessonSlice.reducer;
