import { useState, ReactNode } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  List,
  BoxProps,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItemButtonProps,
} from '@mui/material';
import { actionTag } from '../utils/analytics';
import { Profile } from 'types/user';

// ----------------------------------------------------------------------

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

const admins = [
  'fwdesouza@gmail.com',
  'dmhbr24@gmail.com',
];

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

function NavItem({ item, isShow }: { item: NavItemProps; isShow?: boolean | undefined }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    '&:before': { display: 'block' },
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        </ListItemStyle>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
      onClick={() => actionTag({ category: 'Menu', action: 'Opened', label: title })}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={t(title)} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    items: NavItemProps[];
  }[];
  user?: Profile;
}

export default function NavSection({ navConfig, isShow = true, user, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list, index) => {
        const { items } = list;
        return (
          <List key={index} disablePadding>
            {items.map((item: NavItemProps) => {
              if (user?.email && admins.includes(user?.email)) {
                return (
                  <NavItem key={item.title} item={item} isShow={isShow} />
                );
              }
              if (item.title === 'Generator' && !user?.permission?.includes('admin')) {
                return null;
              }
              return (
                <NavItem key={item.title} item={item} isShow={isShow} />
              );
            })
            }
          </List>
        );
      })}
    </Box>
  );
}
