import { useState, ReactNode, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// hooks
import { useGetUser } from 'hooks/use-user';
import { useGetClass } from 'hooks/use-class';
// pages
import { PATH_AUTH } from 'routes/paths';

import { PATH_DASHBOARD } from '../routes/paths';


// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user: userData } = useGetUser();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const navigate = useNavigate();
  const { status: statusClass, data, error: errorClass, isError: isErrorClass } = useGetClass();
  const authenticated = localStorage.getItem('accessToken');

  useEffect(() => {
    if (statusClass === 'success')
      if (!!userData && (pathname !== '/dashboard/user/account')) {
        if (!data?.payload?.classes)
          return navigate(PATH_DASHBOARD.dashboard);
        if (data?.payload?.classes.length > 0)
          return navigate(PATH_DASHBOARD.dashboard);
      }
  }, []);

  useEffect(() => {
    if (isErrorClass) {
      //@ts-ignore
      errorClass?.includes('Your subscription has expired');
    }

  }, [isErrorClass]);

  if (!authenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
